// extracted by mini-css-extract-plugin
export var btn_wrapper = "oblicz-rate-module--btn_wrapper--35e42";
export var calculator = "oblicz-rate-module--calculator--2db65";
export var calculator_section = "oblicz-rate-module--calculator_section--7cef7";
export var close = "oblicz-rate-module--close--0d202";
export var cta = "oblicz-rate-module--cta--a2219";
export var desktop = "oblicz-rate-module--desktop--e02d7";
export var field_wrapper = "oblicz-rate-module--field_wrapper--efa60";
export var financing_time_wrapper = "oblicz-rate-module--financing_time_wrapper--0e0c0";
export var form = "oblicz-rate-module--form--772b0";
export var info = "oblicz-rate-module--info--4990b";
export var left_wrapper = "oblicz-rate-module--left_wrapper--e69c4";
export var light = "oblicz-rate-module--light--70b70";
export var mobile = "oblicz-rate-module--mobile--96d41";
export var results = "oblicz-rate-module--results--b4ec7";
export var right = "oblicz-rate-module--right--08854";
export var right_wrapper = "oblicz-rate-module--right_wrapper--45da9";
export var separator = "oblicz-rate-module--separator--6d2a1";
export var shadow = "oblicz-rate-module--shadow--95fbc";
export var subtitle = "oblicz-rate-module--subtitle--1f3db";
export var wrapper_popup = "oblicz-rate-module--wrapper_popup--7fe55";