import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select'
import { useQuery, gql } from '@apollo/client';


import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s from './oblicz-rate.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';
import * as s_form from '@assets/styles/form.module.scss';

const customSelectStyles = {
    placeholder: (provided) => ({
        ...provided,
        fontWeight: 300,
        paddingLeft: '8px',
        marginBottom: '-8px',
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: '#FEA00A !important',
        borderRadius: 0,
        borderWidth: '2px',
        boxShadow: state.isFocused ? '0px 4px 10px -5px rgba(66, 68, 90, .4); !important' : ''
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0px 8px'
    }),
    singleValue: (provided) => ({
        ...provided,
        fontWeight: 300,
        color: '#171717',
        marginBottom: '-8px',
        paddingLeft: '8px'
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'rgba(0, 0, 0, .05)' : '#fff !important',
        fontWeight: state.isSelected ? '700' : '300',
        color: '#171717',
        fontSize: '.875rem',
        paddintTop: '4px',
        paddingBottom: '4px'
    })
}

const vehicleOptions = [
	{ value: 'Samochód osobowy', label: 'Samochód osobowy' },
	{ value: 'Samochód dostawczy', label: 'Samochód dostawczy' },
	{ value: 'Samochód ciężarowy', label: 'Samochód ciężarowy' },
	{ value: 'Ciągnik siodłowy', label: 'Ciągnik siodłowy' },
	{ value: 'Naczepa', label: 'Naczepa' },
	{ value: 'Maszyny', label: 'Maszyny' },
	{ value: 'Sprzęt IT', label: 'Sprzęt IT' },
	{ value: 'Motocykl', label: 'Motocykl' },
	{ value: 'Sprzęt rolniczy', label: 'Sprzęt rolniczy' },
	{ value: 'Urządzenie medyczne', label: 'Urządzenie medyczne' },
	{ value: 'Urządzenie kosmetyczne', label: 'Urządzenie kosmetyczne' },
	{ value: 'Fotowoltaika', label: 'Fotowoltaika' },
	{ value: 'Inne', label: 'Inne' },
];

const timeOptions = [
	{ value: 24, label: 24 },
	{ value: 35, label: 35 },
	{ value: 36, label: 36 },
	{ value: 47, label: 47 },
	{ value: 48, label: 48 },
	{ value: 59, label: 59 },
	{ value: 60, label: 60 },
	{ value: 71, label: 71 },
	{ value: 72, label: 72 },
	{ value: 83, label: 83 },
	{ value: 84, label: 84 },
];


const CALCULATOR_QUERY = gql`
	query kalkulatorQuery {
		kalkulators(where: {title: "Kalkulator leasingu"}) {
			nodes {
				kalkulatorLeasingu {
					maszynyMarzaDo100k
					maszynyMarzaPowyzej100k
					samochodyMotocykleMarzaDo100k
					samochodyMotocykleMarzaPowyzej100k
					wibor
					commission
				}
			}
		}
	}
`;

const LeasingCalculator = () => {

	const { loading, error, data: dataQuery } = useQuery(CALCULATOR_QUERY);
    const { register, handleSubmit, control, watch, trigger, setValue, getValues, formState: { errors, isValid } } = useForm({mode: 'onChange'});

	const timeSelect = useRef();

	//wyniki
	const [initPayment, setInitPayment] = useState(null); //in % of total value
	const [initPaymentValue, setInitPaymentValue] = useState(null); //in pln

	const [monthlyInstalment, setMonthlyInstalment] = useState(null); //in % of total value
	const [monthlyInstalmentValue, setMonthlyInstalmentValue] = useState(null); //in pln

	const [buyout, setBuyout] = useState(null); //in % of total value
	const [buyoutValue, setBuyoutValue] = useState(null); //in pln

	const [total, setTotal] = useState(null); //in % of total value
	const [totalValue, setTotalValue] = useState(null); //in pln


	const submitHandler = async (data) => {

		if(!loading && !error){

			//wartosc przedmiotu
			const value = data.value;

			//wplata wlasna w %
			const initPayment = data.init_payment;

			//wykup w %
			const buyout = data.buyout;

			//loaded data z panelu konfiguracyjnego w WordPress
			const loadedData = dataQuery.kalkulators.nodes[0].kalkulatorLeasingu;

			//wibor w %
			const wibor = loadedData.wibor;

			//interest rate calc - marża na podstawie wybranego przedmiotu leasingu w %
			let interestRate = 3;
			switch(data.vehicle) {
				case 'Samochód osobowy':
					interestRate = value < 100000 ? loadedData.samochodyMotocykleMarzaDo100k : loadedData.samochodyMotocykleMarzaPowyzej100k;
					break;
				case 'Samochód dostawczy':
					interestRate = value < 100000 ? loadedData.samochodyMotocykleMarzaDo100k : loadedData.samochodyMotocykleMarzaPowyzej100k;
					break;
				case 'Samochód ciężarowy':
					interestRate = value < 100000 ? loadedData.samochodyMotocykleMarzaDo100k : loadedData.samochodyMotocykleMarzaPowyzej100k;
					break;
				case 'Ciągnik siodłowy':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Naczepa':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Maszyny':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Sprzęt IT':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Motocykl':
					interestRate = value < 100000 ? loadedData.samochodyMotocykleMarzaDo100k : loadedData.samochodyMotocykleMarzaPowyzej100k;
					break;
				case 'Sprzęt rolniczy':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Urządzenie medyczne':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Urządzenie kosmetyczne':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Urządzenie kosmetyczne':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Fotowoltaika':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
				case 'Inne':
					interestRate = value < 100000 ? loadedData.maszynyMarzaDo100k : loadedData.maszynyMarzaPowyzej100k;
					break;
			}

			//prowizja w %
			const commission = loadedData.commission;

			//ilosc miesiecy
			const months = data.time_input ? data.time_input : data.time_select;

			//------------- obliczenia -------------

			//wartosc finansowania do obliczen (minus wplata wlasna)
			let calcValue = value - (value * (initPayment / 100));

			//wartosc wykupu
			const buyoutValue = value * (buyout / 100);

			//wartosc wplaty wlasnej
			const initPaymentValue = value * (initPayment / 100);

			//wartosc prowizji
			const commissionValue = value * (commission / 100);

			//const zmiennaPomocnicza - oprocentowanie / ilosc rat w roku
			const zmiennaPomocnicza = ((wibor / 100) + (interestRate / 100)) / 12;

			//wartosc poczatkowa
			const startValue = buyoutValue / (Math.pow((1+zmiennaPomocnicza), months));


			//kwota kredytu
			const creditValue = value - initPaymentValue - startValue + commissionValue;

			//wartosc pojedynczej raty
			const singleInstallmentValue = creditValue * (zmiennaPomocnicza * Math.pow(1+zmiennaPomocnicza, months)) / (Math.pow(1+zmiennaPomocnicza, months) - 1);


			//suma oplat
			const installmentTotalValue = initPaymentValue + buyoutValue + (singleInstallmentValue * months);

			//rata miesieczna w %
			const singleInstallmentPercentage = singleInstallmentValue / value;

			//suma oplat w %
			const paymentTotalPercentage = ((buyout / 100) + (initPayment / 100) + (months * singleInstallmentPercentage)) * 100;

			//suma oplat
			const paymentTotalValue = initPaymentValue + buyoutValue + (singleInstallmentValue * months);


			// --------------- wyniki -------------------

			setInitPayment(initPayment);
			setInitPaymentValue((initPaymentValue).toFixed(2));

			setMonthlyInstalment((singleInstallmentPercentage * 100).toFixed(2));
			setMonthlyInstalmentValue((singleInstallmentValue).toFixed(2));

			setBuyout(buyout);
			setBuyoutValue((buyoutValue).toFixed(2));

			setTotal((paymentTotalPercentage).toFixed(2));
			setTotalValue((paymentTotalValue).toFixed(2));


			//update form message field
			let message = `Dzień dobry, wysyłam zapytanie o leasing:
				Przedmiot leasingu: ${data.vehicle}
				Rok produkcji: ${data.year}
				Wartość przedmiotu netto: ${data.value}zł
				Okres finansowania: ${months}ms
				Wpłata własna: ${data.init_payment}%
				Wykup: ${data.buyout}%
				`;

			setValuePopup('message', message);

		}
	}


    //popup
    const [showPopup, setShowPopup] = useState(false);
    const { register: registerPopup, handleSubmit: handleSubmitPopup, setValue: setValuePopup, watch: watchPopup, formState: { errors: errorsPopup, isValid: isValidPopup } } = useForm({mode: 'onChange'});
    const [reqStatus, setReqStatus] = useState({isSuccess: false, isLoading: false, isError: false, errorMessage: ''});

	const popupSubmitHandler = async (data) => {
        if(!reqStatus.isSuccess){
            try{
                setReqStatus({isSuccess: false, isLoading: true, isError: false, errorMessage: ''});

                let formData = new FormData();
                formData.append('name-610', data.name);
                formData.append('company-610', data.company);
                formData.append('nip-610', data.nip);
                formData.append('email-133', data.email);
                formData.append('tel-610', data.tel);
                formData.append('message-871', data.message);

                const res = await axios.post(`https://twójleasing.pl/panel/wp-json/contact-form-7/v1/contact-forms/3695/feedback`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                );

				setTimeout(() => {
					setShowPopup(false);
				}, 4000);

                setReqStatus({isSuccess: true, isLoading: false, isError: false, errorMessage: ''});

            } catch (error) {
                setReqStatus({isSuccess: false, isLoading: false, isError: true, errorMessage: 'Wystąpił błąd.'});
            }
        }
	}


	return (
		<MainLayout>

			<Seo
				title='Oblicz ratę - kalkulator leasingu'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
			/>

			<BreadCrumbs />

      		<section className={`${s.calculator_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        		<h1>Oblicz ratę</h1>
				<p className={`${s_text.subtitle} ${s.subtitle}`}>
					Oblicz ratę leasingu i dowiedz się dlaczego warto skorzystać z naszej oferty.
					Skontaktuj się z Nami abyśmy mogli przedstawić Ci jeszcze korzystniejszą ofertę.
                </p>


				<div className={s.calculator}>

                	<form onSubmit={handleSubmit(submitHandler)} className={`${s_form.apply} ${s_form.error_down} ${s.form}`}>

						{/* vehicle type */}
						<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
                            <span className={s_form.error_text}>
								{errors.vehicle?.type === 'required' && 'Wybierz przedmiot leasingu.'}
                            </span>
                            <Controller
                                control={control}
                                name='vehicle'
                                render={({ field: { onChange, value, name, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        value={vehicleOptions.find((c) => c.value === value)}
                                        name={name}
                                        options={vehicleOptions}
                                        styles={customSelectStyles}
                                        isClearable={true}
                                        isSearchable={false}
                                        inputId='calculator_vehicle'
                                        placeholder=''
                                        onChange={(selectedOption) => {
                                            onChange(selectedOption?.value);
                                        }}
                                    />
                                )}
								rules={{
									required: true
								}}
                            />
                            <label className={s_form.select_label} htmlFor='calculator_vehicle'>Przedmiot leasingu</label>
                        </div>

						{/* rok produkcji */}
						<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
                            <span className={s_form.error_text}>
								{errors.year?.type === 'required' && 'Wpisz rok produkcji.'}
								{errors.year?.type === 'validDate' && 'Przedmiot nie może być starszy niż 10 lat.'}
                            </span>
                            <input id='calculator_year' className={errors.year ? s_form.error : ''} placeholder={`Nie starszy niż ${new Date().getFullYear() - 10}`} type="number" min={new Date().getFullYear() - 10} max={new Date().getFullYear()}
                                {...register("year",
									{
										required: true,
										validate: {
											validDate: (val) => val >= new Date().getFullYear() - 10 && val <= new Date().getFullYear()
										}
									}
                                )}
                            />
                            <label className={s_form.select_label} htmlFor='calculator_year'>Rok produkcji</label>
                        </div>

						{/* wartosc netto */}
						<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
                            <span className={s_form.error_text}>
								{errors.value?.type === 'required' && 'Wpisz wartość przedmiotu.'}
								{errors.value?.type === 'tooLow' && 'Wartość musi być wyższa niż 1000zł.'}
                            </span>
                            <input id='calculator_value' className={errors.value ? s_form.error : ''} placeholder="&nbsp;" type="number" min={1000}
                                {...register("value",
									{
										required: true,
										validate: {
											tooLow: (val) => val > 1000
										}
									}
                                )}
                            />
                            <label className={s_form.select_label} htmlFor='calculator_value'>Wartość przedmiotu netto (zł)</label>
                        </div>

						{/* okres finansowania */}
						<div className={s.financing_time_wrapper}>
							{/* select */}
							<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
								<span className={s_form.error_text}>
									{(errors.time_select?.type === 'required' || errors.time_input?.type === 'required') && 'Wybierz okres finansowania.'}
								</span>
								<Controller
									control={control}
									name='time_select'
									render={({ field: { onChange, value, name, ref } }) => (
										<Select
											inputRef={ref}
											ref={timeSelect}
											value={timeOptions.find((c) => c.value === value)}
											name={name}
											options={timeOptions}
											styles={customSelectStyles}
											isClearable={true}
											isSearchable={false}
											inputId='calculator_time_select'
											placeholder=''
											onChange={(selectedOption) => {
												onChange(selectedOption?.value);
												if(selectedOption){
													setValue('time_input', undefined);
												}
												trigger(['time_input', 'time_select']);
												setTimeout(() => {
													trigger(['time_input', 'time_select']);

												}, 100)
											}}
										/>
									)}
									rules={{
										required: getValues('time_input') ? false : true
									}}
								/>
								<label className={s_form.select_label} htmlFor='calculator_time_select'>Okres finansowania (msc)</label>
							</div>

							<div className={s.separator}>
								<span className={s.mobile}>▲</span><span className={s.desktop}>←</span> wybierz lub wpisz <span className={s.desktop}>→</span><span className={s.mobile}>▼</span>
							</div>
							{/* input */}
							<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
								<span className={s_form.error_text}>
									{errors.time_input?.type === 'tooLow' && 'Minimum 24ms.'}
									{errors.time_input?.type === 'tooHigh' && 'Maksimum 84ms.'}
								</span>
								<input id='calculator_time_input' className={errors.time_input ? s_form.error : ''} placeholder="&nbsp;" type="number" min={24} max={84}
									{...register("time_input",
										{
											required: getValues('time_select') ? false : true,
											validate: {
												tooLow: (val) => getValues('time_select') ? true : val >= 24,
												tooHigh: (val) => getValues('time_select') ? true : val <= 84,
											}
										}
									)}
									onChange={(e) => {
										setValue('time_input', e.target.value);
										timeSelect.current.clearValue();
										trigger(['time_input', 'time_select']);
									}}
								/>
								<label className={s_form.select_label} htmlFor='calculator_time_input'>Okres finansowania (msc)</label>
							</div>

						</div>

						{/* wplata wlasna */}
						<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
                            <span className={s_form.error_text}>
								{errors.init_payment?.type === 'required' && 'Wpisz wartość wpłaty własnej.'}
								{errors.init_payment?.type === 'tooLow' && 'Wartość nie może być ujemna.'}
								{errors.init_payment?.type === 'tooHigh' && 'Wartość nie może być wyższa niż 45%.'}
                            </span>
                            <input id='calculator_init_payment' className={errors.init_payment ? s_form.error : ''} placeholder="&nbsp;" type="number" min={0}
                                {...register("init_payment",
									{
										required: true,
										validate: {
											tooLow: (val) => val >= 0,
											tooHigh: (val) => val <= 45
										}
									}
                                )}
                            />
                            <label className={s_form.select_label} htmlFor='calculator_init_payment'>Wpłata własna (%)</label>
                        </div>

						{/* wykup */}
						<div className={`${s_form.field_wrapper} ${s.field_wrapper}`}>
                            <span className={s_form.error_text}>
								{errors.buyout?.type === 'required' && 'Wpisz wartość wykupu.'}
								{errors.buyout?.type === 'tooLow' && 'Wartość nie może być mniejsza niż 1%.'}
								{errors.buyout?.type === 'tooHigh' && 'Wartość nie może być wyższa niż 50%.'}
                            </span>
                            <input id='calculator_buyout' className={errors.buyout ? s_form.error : ''} placeholder="&nbsp;" type="number" min={0}
                                {...register("buyout",
									{
										required: true,
										validate: {
											tooLow: (val) => val >= 1,
											tooHigh: (val) => val <= 50
										}
									}
                                )}
                            />
                            <label className={s_form.select_label} htmlFor='calculator_buyout'>Wykup (%)</label>
                        </div>

						<div className={s.btn_wrapper}>
							<button type="submit" className={`${!isValid ? s_form.inactive : ''} ${s_btns.btn_full}`} >Oblicz finansowanie</button>
						</div>

					</form>

					{/* results */}
					{total && totalValue &&
						<div className={s.results} id='calc_result'>
							<h2>Wynik kalkulacji</h2>

							<table>
								<tbody>
									<tr>
										<th></th>
										<th className={s.light}>% wartości</th>
										<th className={`${s.light} ${s.right}`}>Kwota netto</th>
									</tr>
									<tr>
										<th>Opłata wstępna</th>
										<td>{initPayment}%</td>
										<td>{initPaymentValue}zł</td>
									</tr>
									<tr>
										<th>Rata miesięczna</th>
										<td>{monthlyInstalment}%</td>
										<td>{monthlyInstalmentValue}zł</td>
									</tr>
									<tr>
										<th>Kwota wykupu</th>
										<td>{buyout}%</td>
										<td>{buyoutValue}zł</td>
									</tr>
									<tr>
										<th>Suma opłat</th>
										<td>{total}%</td>
										<td>{totalValue}zł</td>
									</tr>
								</tbody>
							</table>

							<p className={s.info}>
								Kalkulacja została przygotowana w oparciu o aktualny WIBOR. W przypadku zmiany wysokość opłat
								leasingowych może ulec zmianie. Niniejsza oferta nie stanowi oferty w rozumieniu Kodeksu
								Cywilnego i ma jedynie charakter informacyjny
							</p>

							<button className={`${s.cta} ${s_btns.btn_full}`} onClick={() => setShowPopup(true)}>Szybki kontakt</button>


						</div>
					}

					{showPopup == true &&
						<>
							<div className={s.shadow} onClick={() => setShowPopup(false)} />
							<div className={s.wrapper_popup}>
								<div className={s.close} onClick={() => setShowPopup(false)}></div>

								<form onSubmit={handleSubmitPopup(popupSubmitHandler)} className={`${s_form.apply} ${s.form}`}>

									<div className={s.left_wrapper}>

										<h3>Szybki kontakt</h3>

										{/* name */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.name?.type === 'required' && 'Wpisz imię i nazwisko.'}
											</span>
											<input id='form_contact_name' className={errorsPopup.name ? s_form.error : ''} placeholder="&nbsp;" type="text"
												{...registerPopup("name",
													{
														required: true
													}
												)}
											/>
											<label htmlFor='form_contact_name'>Imię i nazwisko*</label>
										</div>

										{/* company */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
											</span>
											<input id='form_contact_company' className={errorsPopup.company ? s_form.error : ''} placeholder="&nbsp;" type="text"
												{...registerPopup("company",

												)}
											/>
											<label htmlFor='form_contact_company'>Nazwa firmy</label>
										</div>

										{/* nip */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.nip?.type === 'pattern' && 'Niepoprawny NIP'}
											</span>
											<input id='form_contact_nip' className={errorsPopup.nip ? s_form.error : ''} placeholder="&nbsp;" type="text"
												onKeyPress={(event) => {
													if (!/[0-9- ]/.test(event.key)) {
														event.preventDefault();
													}
												}}
												{...registerPopup("nip", {
														pattern: /^[0-9- ]*$/
													}
												)}
											/>
											<label htmlFor='form_contact_nip'>NIP</label>
										</div>

										{/* tel */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.tel?.type === 'required' && 'Wpisz numer telefonu.'}
												{errorsPopup.tel?.type === 'pattern' && 'Niepoprawny telefon'}
											</span>
											<input id='form_contact_tel' className={errorsPopup.tel ? s_form.error : ''} placeholder="&nbsp;" type="tel"
												onKeyPress={(event) => {
													if (!/[0-9 +-]/.test(event.key)) {
														event.preventDefault();
													}
												}}
												{...registerPopup("tel",
													{
														required: true,
														pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
													}
												)}
											/>
											<label htmlFor='form_contact_tel'>Numer telefonu*</label>
										</div>

										{/* email */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.email?.type === 'required' && 'Wpisz adres e-mail.'}
												{errorsPopup.email?.type === 'pattern' && 'Adres email wygląda na nieprawidłowy.'}
											</span>
											<input id='form_contact_email' className={errorsPopup.email ? s_form.error : ''} placeholder="&nbsp;" type="email"
												{...registerPopup("email",
													{
														required: true,
														pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
													}
												)}
											/>
											<label htmlFor='form_contact_email'>E-mail*</label>
										</div>

									</div>

									<div className={s.right_wrapper}>

										<h3>Treść wiadomości</h3>

										{/* message */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.message?.type === 'required' && 'Wpisz wiadomość.'}
											</span>
											<div className={s_form.textarea_wrapper}>
												<textarea id='form_contact_message' className={errorsPopup.message ? s_form.error : ''} placeholder="&nbsp;"
													{...registerPopup("message",
														{
															required: true
														}
													)}
												/>
												<label htmlFor='form_contact_message'>Treść wiadomości*</label>
											</div>
										</div>

										{/* rules */}
										<div className={s_form.field_wrapper}>
											<span className={s_form.error_text}>
												{errorsPopup.rules && 'Zaznacz wymagane zgody.'}
											</span>
											<div className={s_form.checkbox_wrapper}>
												<input className={errorsPopup.rules ? s_form.error : ''} type="checkbox"
													id={'form_contact_rules'}
													{...registerPopup("rules",
														{
															required: true
														}
													)}
												/>
												<label className={s_form.checkbox_label} htmlFor={'form_contact_rules'}>Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z realizacją mojego zapytania, oraz o kontakt drogą telefoniczną lub elektroniczną. <a href='/polityka-prywatnosci.pdf' target='_blank'>Zobacz&nbsp;Politykę&nbsp;prywatności</a> *</label>
											</div>
										</div>

										<div className={s_form.request_response}>
											<span className={s_form.error}>
												{reqStatus.isError && reqStatus.errorMessage}
											</span>
											<span className={s_form.success}>
												{reqStatus.isSuccess && 'Wysłano!'}
											</span>
										</div>

										{/* //loading spinner or effect on btn */}
										<button type="submit" className={`${!isValidPopup || reqStatus.isSuccess || reqStatus.isLoading ? s_form.inactive : ''} ${s_btns.btn_full}`} >Wyślij</button>

									</div>
								</form>

							</div>
						</>
					}

				</div>


			</section>

			<ContactSection />
      		<MapSection />

		</MainLayout>
	)
};

export default LeasingCalculator;
